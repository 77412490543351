import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "../index.css";
import {
  ChevronDownIcon,
  SearchIcon,
  SortAscendingIcon,
  DotsVerticalIcon,
} from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import Button from "./Button";
import classNames from "classnames";

const SectionHeading = ({
  type,
  title,
  label,
  description,
  primaryCta,
  primaryOnClick,
  secondaryCta,
  secondaryOnClick,
  placeholder,
  useMore,
  searchBar,
  tabs,
  ...rest
}) => {
  const containerStyle =
    type === "tabs" && !primaryCta && !secondaryCta
      ? "sm:pb-0"
      : type === "tabs"
      ? "relative sm:pb-0"
      : "";

  const innerContainerStyle =
    type === "simple" && !description && !useMore
      ? "md:flex md:items-center md:justify-between"
      : type === "tabs" && (primaryCta || secondaryCta)
      ? "sm:flex sm:items-center sm:justify-between"
      : "sm:flex sm:justify-between sm:items-baseline";

  const actionStyle =
    type === "tabs" && (primaryCta || secondaryCta)
      ? "md:mt-0 md:absolute md:top-3 md:right-0 "
      : "sm:mt-0 sm:ml-4";

  const titleContainerStyle = label
    ? "flex flex-wrap items-baseline"
    : "sm:w-0 sm:flex-1";

  return (
    <div
      className={classNames(
        "py-6 border-b border-gray-200 px-8 bg-white",
        containerStyle
      )}
    >
      <div
        className={classNames(
          label
            ? "-ml-2 -mt-2 flex flex-wrap items-baseline"
            : innerContainerStyle
        )}
      >
        <div className={titleContainerStyle}>
          <h3
            className={classNames(
              label ? "ml-2 mt-2" : "",
              "text-lg leading-6 font-medium text-gray-900"
            )}
          >
            {title}
          </h3>
          {description && (
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              {description}
            </p>
          )}
        </div>
        {label && (
          <p className="ml-2 mt-1 text-sm text-gray-500 truncate ">{label}</p>
        )}

        {primaryCta || secondaryCta ? (
          <div className={classNames("mt-3  ", actionStyle)}>
            {secondaryCta && (
              <Button
                buttonStyle="secondary"
                className="mr-3"
                onClick={secondaryOnClick}
              >
                <span>{secondaryCta}</span>
              </Button>
            )}

            {primaryCta && (
              <Button className="capitalize" onClick={primaryOnClick}>
                <span>{primaryCta}</span>
              </Button>
            )}
          </div>
        ) : (
          ""
        )}

        {searchBar ? (
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
                  placeholder={placeholder}
                />
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder={placeholder}
                />
              </div>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2">Sort</span>
                <ChevronDownIcon
                  className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {useMore ? (
          <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
              Open
            </span>
            <Menu as="div" className="ml-3 relative inline-block text-left">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {tabs.map((tab) => (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex justify-between px-4 py-2 text-sm"
                                )}
                              >
                                {tab.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        ) : (
          ""
        )}
      </div>

      {type === "tabs" ? (
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

SectionHeading.defaultProps = {
  type: "simple",
  searchBar: false,
  useMore: false,
};

SectionHeading.propTypes = {
  /**
   * Type
   */
  type: PropTypes.oneOf(["simple", "tabs"]),

  /**
   * Title
   */
  title: PropTypes.string,

  /**
   * Label
   */
  label: PropTypes.string,

  /**
   * Description
   */
  description: PropTypes.string,

  /**
   * Primary CTA
   */
  primaryCta: PropTypes.string,

  /**
   * Primary OnClick Action
   */
  primaryOnClick: PropTypes.func,

  /**
   * Secondary CTA
   */
  secondaryCta: PropTypes.string,

  /**
   * Secondary OnClick Action
   */
  secondaryOnClick: PropTypes.func,

  /**
   * Placeholder
   */
  placeholder: PropTypes.string,

  /**
   * For Dropdown
   */
  useMore: PropTypes.bool,

  /**
   * Dropdown Data
   */
  tabs: PropTypes.array,
};

export default SectionHeading;
