import React from "react";
import _ from "lodash";
import classNames from "classnames";
function Shimmer({ rows = 5 }) {
  return (
    <div className="w-full mx-auto flex flex-col">
      {_.fill(new Array(rows), 0).map((i, idx) => (
        <div className="animate-pulse flex space-x-4 flex-1" key={`r-${idx}`}>
          <div
            className={classNames(
              "flex-1 flex flex-row space-x-8 py-1 justify-between h-20",
              idx % 2 === 0 ? "bg-gray-100" : "bg-white"
            )}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default Shimmer;
