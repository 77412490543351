import React, { useRef, useEffect } from "react";
import { Link, navigate, useLocation } from "@reach/router";
import { Formik, Form } from "formik";
import { LockClosedIcon } from "@heroicons/react/solid";
import TextInput from "../../components/TextInput";
import Button from "../../stories/Button";
import Label from "../../components/Label";
import { signInValidationSchema } from "../../forms/validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { CurrentUser } from "../../lib/network/auth/Auth";
import classNames from "classnames";
import { BASE_API } from "../../utils/Helper";

export default function Signin() {
  const location = useLocation();
  const initialValues = { email: "", password: "" };
  const buttonRef = useRef(null);
  const onSubmit = async (values, { setSubmitting }) => {
    const apiEndpoint = `${BASE_API?.USER}/prod/login`;
    const { data, error } = await API.post(apiEndpoint, {
      email: values?.email,
      password: values?.password,
    });
    if (error) {
      toast.error(error.message);
      buttonRef.current.shake();
    } else {
      await CurrentUser.setToken(data.token);
      // eslint-disable-next-line
      const [headers, payload, signature] = data?.token?.split(".");
      await CurrentUser.setDetails(atob(payload));
      window.location.href = "/users";
    }
  };

  useEffect(() => {
    if (CurrentUser.getToken()) {
      navigate("/");
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mx-auto mt-6 w-auto text-center text-3xl font-extrabold uppercase text-gray-900">
              urdu reader
            </h2>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Login to your account
            </h2>
          </div>
          <div className="bg-gray-100">
            <span className="relative z-0 flex justify-center  space-x-2 rounded-md pt-4">
              <div
                className={classNames(
                  "text-md rounded-l-md  rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-center font-medium capitalize text-gray-800  sm:mx-auto sm:w-full sm:max-w-md"
                )}
              >
                admin portal
              </div>
            </span>
          </div>

          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md ">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Form className="space-y-6">
                <div>
                  <Label labelFor="email">Email Address</Label>
                  <TextInput
                    name="email"
                    id="email"
                    type="email"
                    placeholder="joe@milestand.com"
                  />
                </div>
                <div>
                  <Label labelFor="password">Password</Label>
                  <TextInput
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Min 8 characters"
                  />
                </div>
                <div className="flex justify-end ">
                  <Link
                    to="/forgot-password"
                    className="text-sm font-medium text-red-700 hover:text-red-600"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <Button
                  ref={buttonRef}
                  type="submit"
                  className="w-full"
                  loading={isSubmitting}
                  iconAlso={true}
                  iconPlacement="leading"
                  icon={<LockClosedIcon />}
                >
                  Login
                </Button>
              </Form>
            </div>
          </div>

          <footer className="max-w-container  mx-auto flex  w-full flex-col items-center justify-center py-10 text-center text-sm text-gray-700 md:flex-row lg:fixed lg:bottom-0 lg:left-0 ">
            <p>© 2022 Milestand Inc. All rights reserved.</p>

            <Link
              className="ml-3 pl-3 text-gray-700 hover:text-blue-700 md:border-l md:border-gray-400"
              to="#"
            >
              Privacy Policy
            </Link>

            <Link
              className="ml-3 pl-3 text-gray-700 hover:text-blue-700  md:border-l md:border-gray-400"
              to="#"
            >
              Terms
            </Link>
          </footer>
        </div>
      )}
    </Formik>
  );
}
