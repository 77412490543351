import { RefreshIcon } from "@heroicons/react/outline";
import Loader from "../components/Loader";
import Shimmer from "../components/Shimmer";
import EmptyState from "../stories/EmptyState";
import _ from "lodash";

const emptyListImage = "/assets/images/placeholder/emptyList.svg";
const emptySearchImage = "/assets/images/placeholder/emptySearch.svg";

export function LoadContentHelper({
  title,
  data,
  loading,
  error,
  mutate,
  isValidating,
  searchTerm,
  ctaOnClick,
  cta,
  message,
}) {
  if (error && !isValidating) {
    return (
      <EmptyState
        cta="Try Again"
        imgSrc="/assets/images/placeholder/error.svg"
        message="We’re facing some technical issue, please check back after sometime or click on the Try again button below to refresh the page."
        onClick={mutate}
        title="Something went wrong"
        icon={<RefreshIcon />}
      />
    );
  }
  if (loading) {
    return (
      <div className="flex h-96 items-center justify-center bg-gray-50">
        <Loader title={`Fetching ${title} ...`} />
      </div>
    );
  }

  if (isValidating) {
    return (
      <div className="flex h-96 items-center justify-center bg-gray-50">
        <Shimmer />
      </div>
    );
  }
  if (_.isEmpty(data)) {
    if (searchTerm) {
      return (
        <EmptyState
          imgSrc={emptySearchImage}
          title={`No results found for "${searchTerm}", please try again. `}
        />
      );
    }
    return (
      <EmptyState
        imgSrc={emptyListImage}
        title={`${title} is empty`}
        message={
          !!ctaOnClick ? (message ? message : `No Data available`) : null
        }
        cta={cta ? cta : !!ctaOnClick ? `Add ${title} ` : null}
        onClick={ctaOnClick}
      />
    );
  }

  return (
    <EmptyState
      imgSrc={emptyListImage}
      title={`Data not found!`}
      message={`No Data available, please! try again.`}
    />
  );
}
