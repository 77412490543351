import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const TextInput = ({ showErrorIcon = true, disable = false, ...props }) => {
  const [field, meta] = useField(props);

  const invalidChars = ["-", "+", "e", "E"];
  const checkChars = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="mt-1 relative">
        <input
          className={classNames(
            " shadow-sm focus:ring-gray-900 focus:border-gray-900 block w-full sm:text-sm border-gray-300 rounded-md",
            meta.touched &&
              meta.error &&
              "border border-red-600 focus:ring-red-600 focus:border-red-600"
          )}
          onKeyDown={(e) => (props.type === "number" ? checkChars(e) : "")}
          name={props.name}
          disabled={!!disable}
          {...field}
          {...props}
        />
        {showErrorIcon && meta.touched && meta.error && (
          <ExclamationCircleIcon
            className=" absolute h-5 w-5 text-red-500 right-2 top-2"
            aria-hidden="true"
          />
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

TextInput.defaultProps = {};

TextInput.propTypes = {
  /**
   * TextInput Type
   */
  type: PropTypes.string.isRequired,

  /**
   * TextInput name attribute
   */
  name: PropTypes.string,
};
export default TextInput;
