import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";

const Avatar = ({
  size,
  imageSrc,
  circular,
  notification,
  notificationBottom,
  notificationBadgeColor,
  iconPlaceholder,
  name,
  initialsPlaceholder,
  ...rest
}) => {
  const sizeStyle = () => {
    switch (size) {
      case "xs":
        return "h-6 w-6 ";
      case "sm":
        return "h-8 w-8";
      case "md":
        return "h-10 w-10";
      case "lg":
        return "h-12 w-12";
      case "xl":
        return "h-14 w-14";
      default:
        return "";
    }
  };

  const circularStyle = circular ? "rounded-full" : "rounded-md";

  const notiSize = () => {
    switch (size) {
      case "xs":
        return "h-1.5 w-1.5 ";
      case "sm":
        return "h-2 w-2";
      case "md":
        return "h-2.5 w-2.5";
      case "lg":
        return "h-3 w-3";
      case "xl":
        return "h-3.5 w-3.5";
      default:
        return "";
    }
  };

  const fontSize = () => {
    switch (size) {
      case "xs":
        return "text-xs";
      case "sm":
        return "text-sm";
      case "lg":
        return "text-lg";
      case "xl":
        return "text-xl";
      default:
        return "";
    }
  };

  function getInitials() {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let result = [...name.matchAll(rgx)] || [];
    return (
      (result.shift()?.[1] || "") + (result.pop()?.[1] || "")
    ).toUpperCase();
  }

  return (
    <>
      <span
        className={classNames(
          "inline-block relative",
          circularStyle,
          iconPlaceholder
            ? "bg-gray-100"
            : initialsPlaceholder
            ? classNames(
                "bg-gray-500 inline-flex items-center justify-center",
                sizeStyle()
              )
            : ""
        )}
      >
        <span
          className={classNames(
            fontSize(),
            "font-medium leading-none text-white",
            initialsPlaceholder && !iconPlaceholder ? "block" : "hidden"
          )}
        >
          {name ? getInitials() : null}
        </span>

        <img
          className={classNames(
            iconPlaceholder || initialsPlaceholder ? "hidden" : "block",
            circularStyle,
            sizeStyle()
          )}
          src={imageSrc}
          alt=""
        />

        <svg
          className={classNames(
            iconPlaceholder && !initialsPlaceholder ? "block" : "hidden",
            circularStyle,
            sizeStyle(),
            "text-gray-300"
          )}
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>

        <span
          className={classNames(
            "absolute right-0 rounded-full ring-2 ring-white",
            notificationBadgeColor,
            notification ? "block" : "hidden",
            notiSize(),
            !circular && notificationBottom
              ? "transform translate-y-1/2 translate-x-1/2"
              : !circular && !notificationBottom
              ? "transform -translate-y-1/2 translate-x-1/2"
              : "",
            notificationBottom ? "bottom-0" : "top-0"
          )}
        />
      </span>
    </>
  );
};

Avatar.defaultProps = {
  size: "md",
  circular: true,
  notification: false,
  notificationBottom: true,
  iconPlaceholder: false,
  initialsPlaceholder: false,
};

Avatar.propTypes = {
  /**
   * Size
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

  /**
   *  Image Source
   */
  imageSrc: PropTypes.string,

  /**
   * Shape
   */
  circular: PropTypes.bool,

  /**
   * Notification
   */
  notification: PropTypes.bool,

  /**
   * Notification Position
   */
  notificationBottom: PropTypes.bool,

  /**
   *  Notification Badge Color
   */
  notificationBadgeColor: PropTypes.string,

  /**
   * Icon Placeholder
   */
  iconPlaceholder: PropTypes.bool,

  /**
   *  Name
   */
  name: PropTypes.string,

  /**
   *  Initials Placeholder
   */
  initialsPlaceholder: PropTypes.bool,
};

export default Avatar;
