import classNames from "classnames";
const InfoContainer = ({ title, label, value, darkMode = false }) => {
  return (
    <div className="mb-4 flex flex-col">
      {title ? (
        <div className=" mb-3 text-lg font-medium leading-6 text-gray-900">
          {title}
        </div>
      ) : (
        ""
      )}
      <div
        className={classNames(
          "block text-sm font-medium ",
          darkMode ? "text-gray-700" : "text-gray-400"
        )}
      >
        {label}
      </div>
      <div
        className={classNames(
          !darkMode ? "bold text-base font-medium capitalize text-gray-700" : ""
        )}
      >
        {value}
      </div>
    </div>
  );
};

export default InfoContainer;
