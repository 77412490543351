import React, { useRef } from "react";
import { Formik, Form } from "formik";
import TextInput from "../../components/TextInput";
import Button from "../../stories/Button";
import Label from "../../components/Label";
import { navigate, useLocation } from "@reach/router";
import { resetPasswordValidationSchema } from "../../forms/validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { BASE_API } from "../../utils/Helper";
export default function ResetPassword() {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token") ?? "";

  const initialValues = {
    passwordResetToken: token,
    password: "",
    passwordConfirmation: "",
  };
  const buttonRef = useRef(null);
  const onSubmit = async (values) => {
    const apiEndpoint = `${BASE_API.USER}/prod/resetPassword`;
    const { error } = await API.post(apiEndpoint, {
      data: { attributes: values },
    });
    if (error) {
      toast.error(
        error.message ? error.message : "Something went Wrong. Please try again"
      );
      buttonRef.current.shake();
    } else {
      navigate("/signin");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 sm:px-6 lg:px-8">
          <div className="px-16 sm:mx-auto sm:w-full sm:max-w-md ">
            <h2 className="mx-auto mt-6 w-auto text-center text-3xl font-extrabold uppercase text-gray-900">
              urdu reader
            </h2>
            <div className="mx-3">
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Reset Password
              </h2>
              <h5 className="mt-2.5 text-center text-sm leading-5 text-gray-500">
                Please enter new password
              </h5>
            </div>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Form className="space-y-7">
                <div>
                  <Label labelFor="password">New Password</Label>
                  <TextInput
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Min 8 characters"
                  />
                </div>
                <div className="mt-4">
                  <Label labelFor="passwordConfirmation">
                    {" "}
                    Confirm Password
                  </Label>
                  <TextInput
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    type="password"
                    placeholder="Min 8 characters"
                  />
                </div>
                <Button
                  ref={buttonRef}
                  type="submit"
                  className="w-full"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
