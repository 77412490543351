import Button from "../stories/Button";
import {
  PencilIcon,
  TrashIcon,
  LockOpenIcon,
  BanIcon,
  CloudDownloadIcon,
  XIcon,
  CheckIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  CashIcon,
} from "@heroicons/react/solid";

export function ActionDeleteButton({ onClick, iconOnly = false }) {
  return (
    <Button
      buttonStyle="danger"
      iconAlso={true}
      iconPlacement="leading"
      icon={<TrashIcon />}
      onClick={onClick}
    >
      {iconOnly ? null : "Delete"}
    </Button>
  );
}

export function ActionEditButton({
  onClick,
  buttonStyle = "primary",
  iconOnly = false,
  ...rest
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      onClick={onClick}
      iconAlso={true}
      iconPlacement="leading"
      icon={<PencilIcon />}
      {...rest}
    >
      {iconOnly ? null : "Edit"}
    </Button>
  );
}

export function ActionUnBlockButton({ onClick, iconOnly = false }) {
  return (
    <Button
      onClick={onClick}
      iconAlso={true}
      iconPlacement="leading"
      icon={<LockOpenIcon />}
    >
      {iconOnly ? null : "Unblock"}
    </Button>
  );
}

export function ActionBlockAccountButton({ onClick, iconOnly = false }) {
  return (
    <Button
      buttonStyle="secondary"
      iconAlso={true}
      iconPlacement="leading"
      icon={<BanIcon />}
      onClick={onClick}
    >
      {iconOnly ? null : "Block Account"}
    </Button>
  );
}

export function ExportDataButton({ onClick, iconOnly = false }) {
  return (
    <Button
      buttonStyle="secondary"
      iconAlso={true}
      iconPlacement="leading"
      icon={<CloudDownloadIcon />}
      onClick={onClick}
    >
      {iconOnly ? null : "Export Data"}
    </Button>
  );
}

export function ActionCancelButton({
  onClick,
  buttonStyle = "secondary",
  size,
  iconOnly = false,
  className,
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      iconAlso={true}
      iconPlacement="leading"
      icon={<XIcon />}
      onClick={onClick}
      className={className}
      size={size}
    >
      {iconOnly ? null : "Cancel"}
    </Button>
  );
}

export function ActionSaveButton({
  onClick,
  iconOnly = false,
  buttonRef,
  ...rest
}) {
  return (
    <Button
      iconAlso={true}
      iconPlacement="leading"
      icon={<CheckIcon />}
      onClick={onClick}
      ref={buttonRef}
      {...rest}
    >
      {iconOnly ? null : "Save"}
    </Button>
  );
}

export function ViewDataButton({
  onClick,
  buttonStyle = "primary",
  iconOnly = false,
  ...rest
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      onClick={onClick}
      iconAlso={true}
      iconPlacement="leading"
      icon={<InformationCircleIcon />}
      {...rest}
    >
      {iconOnly ? null : "View Data"}
    </Button>
  );
}

export function ActionApproveButton({
  onClick,
  buttonStyle = "success",
  iconOnly = false,
  ...rest
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      iconAlso={true}
      iconPlacement="leading"
      icon={<CheckCircleIcon />}
      onClick={onClick}
      {...rest}
    >
      {iconOnly ? null : "Approve"}
    </Button>
  );
}

export function ActionRejectButton({
  onClick,
  buttonStyle = "danger",
  iconOnly = false,
  ...rest
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      iconAlso={true}
      iconPlacement="leading"
      icon={<BanIcon />}
      onClick={onClick}
      {...rest}
    >
      {iconOnly ? null : "Reject"}
    </Button>
  );
}

export function ActionConvertButton({
  onClick,
  buttonStyle = "success",
  iconOnly = false,
  ...rest
}) {
  return (
    <Button
      buttonStyle={buttonStyle}
      iconAlso={true}
      iconPlacement="leading"
      icon={<CashIcon />}
      onClick={onClick}
      {...rest}
    >
      {iconOnly ? null : "Convert"}
    </Button>
  );
}
