import React from "react";
import classNames from "classnames";

const Label = ({ children, labelFor, className }) => {
  return (
    <label
      className={classNames(
        "block text-sm font-medium text-gray-700",
        className
      )}
      htmlFor={labelFor}
    >
      {children}
    </label>
  );
};

export default Label;
