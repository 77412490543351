import React, { useState, useEffect } from "react";
import { useLocation, navigate } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import { useJobList } from "../../lib/services/JobListService";
import Table from "../../components/Table";
import { statusCode } from "../../utils/Helper";
import moment from "moment";
import { ViewDataButton } from "../../table-components/Buttons";

import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";

const JobList = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  //dymanic content changing  base dof url params
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const [modalTalentId, setModalTalentId] = useState("");

  //to manage delete transition of headlessUI
  useEffect(() => {
    if (!modalTalentId) {
      setModalTalentId(deleteId);
    } else {
      setTimeout(() => {
        setModalTalentId(deleteId);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDeleteId, deleteId]);

  const { jobs, loading, error, mutate, isValidating, paginationInfo } =
    useJobList(pageNumberQuery, searchParamsQuery);

  const total_pages = Number(paginationInfo?.totalPages);

  const columns = [
    {
      field: "job ID",
      render: (rowData) => (
        <div className="flex  flex-row items-center px-4 text-left text-sm   capitalize text-gray-900">
          {rowData?.id}
        </div>
      ),
    },
    {
      field: "job name",
      render: (rowData) => (
        <div className="flex  flex-row items-center text-left text-sm  capitalize  text-gray-900 ">
          {rowData?.name}
        </div>
      ),
    },

    {
      field: "file type",
      render: (rowData) => (
        <div className="  whitespace-nowrap text-left  text-sm uppercase text-gray-500 ">
          {!_.isNil(rowData?.isFile)
            ? !rowData?.isFile
              ? !!rowData?.fileExt
                ? rowData?.fileExt
                : "image"
              : !!rowData?.fileExt
              ? rowData?.fileExt
              : "-na"
            : "-na-"}
        </div>
      ),
    },

    {
      field: "status",
      render: (rowData) => (
        <div className="whitespace-nowrap text-left text-sm uppercase text-gray-500">
          {statusCode(rowData?.status)}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm text-gray-500">
          {rowData?.createdAt
            ? moment(`${rowData?.createdAt}`).format("lll")
            : null}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" flex flex-row space-x-4 whitespace-nowrap text-left text-sm font-medium ">
          <ViewDataButton
            iconOnly
            onClick={() => {
              navigate(`/view-job/${rowData?.id}`);
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(jobs)) {
      return (
        <LoadContentHelper
          title="Job list"
          data={jobs}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={jobs} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50">
        <SectionHeading title="Jobs" />
      </div>

      <main className="relative flex-1 overflow-y-auto focus:outline-none">
        <TableLayout
          title={`Job List ${
            _.isNil(paginationInfo)
              ? ""
              : `(Total Jobs : ${paginationInfo?.totalRecords})`
          }`}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default JobList;
