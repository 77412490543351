import { useAPI } from "../network/SWR";
import { BASE_API, queryStringParseFuntion } from "../../utils/Helper";
export const useJobList = (pageParam = "1", searchTerm) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `${BASE_API.JOB}/prod/allJobs${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    jobs: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.pagination || null,
  };
};

//view single job
export const useJob = (userId) => {
  const queryObject = { id: userId };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `${BASE_API.JOB}/prod/showJobAdmin${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    jobData: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
