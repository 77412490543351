import React from "react";
import _ from "lodash";
import Button from "../../stories/Button";
import Author from "../../stories/Author";
import SideNav from "./SideNavContent";
import { LogoutIcon, IdentificationIcon } from "@heroicons/react/outline";
import { CurrentUser } from "../../lib/network/auth/Auth";
import classNames from "classnames";
import Loader from "../../components/Loader";

const DesktopSideBar = () => {
  const profileDetails = CurrentUser?.getProfile();
  const onClickHandler = (e) => {
    e.preventDefault();
    CurrentUser.logout();
  };
  const loadContent = () => {
    if (
      _.isEmpty(profileDetails?.firstName) &&
      _.isEmpty(profileDetails?.lastName)
    ) {
      return (
        <div className="my-1.5 flex w-full items-center justify-center ">
          <Loader className="text-gray-50" />
        </div>
      );
    }
    return (
      <Author
        name={`${profileDetails?.firstName ?? "-NA-"} ${
          profileDetails?.lastName ?? ""
        }`}
        darkmode={false}
        cta={`${profileDetails?.email}`}
        onClick={null}
        imageSrc={profileDetails?.image}
      />
    );
  };
  return (
    <div
      className={classNames(
        "hidden w-72 border-r-2 border-gray-300  md:flex",
        "cutom-sidebar-scrollbar"
      )}
    >
      <div className="flex flex-1 flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex h-0 flex-1 flex-col ">
          <div className="flex flex-shrink-0 items-center px-4 pt-5 pb-3 ">
            <img
              className=" h-6 w-auto"
              src="/assets/images/logo/urduReader-logo.png"
              alt="logo"
            />
          </div>
          <div className=" flex justify-start text-sm font-medium capitalize ">
            <div className="mx-3 flex rounded-md px-2 text-white">
              <div className=" mr-1 flex">
                <IdentificationIcon className="mt-0.5 mr-1 h-4 w-4" />
                <h4>admin portal</h4>
              </div>
            </div>
          </div>
          <hr className="my-2 mx-3" />
          <div className="flex flex-1 flex-col overflow-y-auto pr-3 pb-4">
            <SideNav />
          </div>
          <div className="flex flex-shrink-0 p-4">
            <Button
              type="submit"
              className="mx-auto w-full"
              iconAlso={true}
              iconPlacement="leading"
              icon={<LogoutIcon />}
              buttonStyle="white"
              onClick={onClickHandler}
            >
              Logout
            </Button>
          </div>
          <div className="flex flex-shrink-0 bg-gray-900 p-4  ">
            {loadContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSideBar;
