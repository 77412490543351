import { navigate } from "@reach/router";
import queryString from "query-string";

/* 
--------------------------------
navigateSelf function Parameters
--------------------------------
PageNo --> Current Page number
query ---> Search Query
parsed --->  retruning location.search in an object form
*/
export const navigateSelf = (pageNo, query, parsed) => {
  navigate(
    queryString.stringifyUrl(
      {
        url: "",
        query: {
          ...parsed,
          pageNo,
          query,
        },
      },
      { skipNull: true, skipEmptyString: true }
    )
  );
};

/* 
 for backend url query parsing only
 queryObject --> Contains all params objects
*/
export const queryStringParseFuntion = (queryObject) => {
  return queryString.stringifyUrl(
    {
      url: "",
      query: queryObject,
    },
    { skipNull: true, skipEmptyString: true }
  );
};

export const statusCode = (key) => {
  switch (key) {
    case 0:
      return "draft";
    case 1:
      return "In progress";
    case 2:
      return "Completed";
    case 4:
      return "failed";

    default:
      return "-NA-";
  }
};

// api base route constant
export const BASE_API = {
  USER: "https://rxccbku42d.execute-api.ap-south-1.amazonaws.com",
  JOB: "https://ls8pbbxod9.execute-api.ap-south-1.amazonaws.com",
  // TWITTER: "http://127.0.0.1:8787", 
  TWITTER: "https://tweet-api-production.nasir-660.workers.dev", 
  STORAGE: "https://pub-36702f4a2ecb45db9d05e03ec1129c87.r2.dev"
};


