import { useAPI } from "../network/SWR";
import { BASE_API, queryStringParseFuntion } from "../../utils/Helper";
export const useUserList = (pageParam = "1", searchTerm) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `${BASE_API.USER}/prod/allUsers${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    users: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.pagination || null,
  };
};
