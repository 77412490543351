import React from "react";
import { useParams, navigate } from "@reach/router";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import Breadcrumb from "../../stories/Breadcrumb";
import _ from "lodash";
import { useJob } from "../../lib/services/JobListService";
import moment from "moment";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import JobDetails from "../../components/JobDetails";
import { statusCode } from "../../utils/Helper";

const ViewJob = () => {
  const { jobId } = useParams();
  const { jobData, mutate, isValidating, loading, error } = useJob(jobId);

  const breadcrumbsData = [
    {
      to: -1,
      name: "Jobs",
    },
    {
      to: "",
      name: jobData?.name,
      current: true,
    },
  ];

  const jobFields = [
    {
      label: "Job Name",
      value: jobData?.name ?? "-NA-",
    },
    { label: "Job Status", value: statusCode(jobData?.status) },
    {
      label: "Job Id",
      value: jobData?.id ?? "-NA-",
    },
    {
      label: "User Id",
      value: jobData?.UserId ?? "-NA-",
    },
    {
      label: "Created On",
      value: moment(jobData?.createdAt).format("lll") ?? "-NA-",
    },
    {
      label: "File Type",
      value: !_.isNil(jobData?.isFile)
        ? !jobData?.isFile
          ? !!jobData?.fileExt
            ? jobData?.fileExt
            : "image"
          : !!jobData?.fileExt
          ? jobData?.fileExt
          : "-na"
        : "-na-",
    },
    {
      label: "Updated On",
      value: moment(jobData?.updatedAt).format("lll") ?? "-NA-",
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(jobData)) {
      return (
        <div className="my-4 px-3">
          <LoadContentHelper
            title="Job data"
            data={jobData}
            loading={loading}
            error={error}
            mutate={mutate}
            isValidating={isValidating}
          />
        </div>
      );
    }
    return <JobDetails jobFields={jobFields} jobData={jobData} />;
  };

  return (
    <Layout>
      <div className="sticky top-0 z-50 bg-white pt-4">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading
          title="Job Details"
          primaryCta="Back"
          primaryOnClick={() => navigate(-1)}
        />
      </div>
      <div className=" my-8 mx-6 rounded-md bg-white px-3 pt-6">
        <h3 className="px-3 text-lg font-medium leading-6 text-gray-900">
          Job Data
        </h3>
        <div className="pb-4">{loadContent()}</div>
      </div>
    </Layout>
  );
};

export default ViewJob;
