import { Router, Redirect } from "@reach/router";
import { CurrentUser } from "./lib/network/auth/Auth";

//Open Pages
import Signin from "./pages/Auth/Signin";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";

import Home from "./pages/Home";
import JobList from "./pages/navigation/JobList";
import ViewJob from "./pages/navigation/ViewJob";
import UserList from "./pages/navigation/UserList";
import NotFound from "./pages/NotFound";
import TweetsList from "./pages/navigation/TweetsList";
import TwitterAuth from "./pages/Auth/twitterAuth";
import AuthorsList from "./pages/navigation/AuthorsList";

const ProtectedRoute = ({ children }) => {
  const token = CurrentUser.getToken();
  return !!token ? children : <Redirect to="/signin" noThrow />;
};

function App() {
  return (
    <Router>
      <ProtectedRoute path="/">
        <Home path="/" />

        <UserList path="/users" />
        <JobList path="/job-list" />
        <AuthorsList path="/authors" />
        <ViewJob path="/view-job/:jobId" />

        {/* <TweetsList path="/tweets" /> */}
        <NotFound default />
      </ProtectedRoute>
      {/* open routes */}

      <Signin path="/signin" />

      <ForgotPassword path="/forgot-password" />
      <ResetPassword path="/reset-password" />

      {/* Default Route */}
      <Signin default />
    </Router>
  );
}

export default App;
