import React from "react";
import InfoContainer from "./InfoContainer";

const JobDetails = ({ jobFields, jobData }) => {
  const jobFieldArr = jobFields;
  return (
    <>
      <div className="my-6 flex w-full flex-wrap px-3">
        {jobFieldArr.map((item, idx) => {
          return (
            <div className="my-2 w-1/2 pr-4" key={idx}>
              <InfoContainer label={item?.label} value={item?.value} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default JobDetails;
