import React from "react";
import Avatar from "../stories/Avatar";
import Button from "../stories/Button";

const AvatarCard = ({
  name,
  secondaryField,
  imageSrc,
  circular = true,
  button = true,
  primaryCta = "Add",
  onClickBtn,
  ...props
}) => {
  return (
    <div className="flex flex-row items-center justify-center  gap-x-4 gap-y-4 py-1 px-2 ">
      <Avatar
        circular={circular}
        size="xl"
        imageSrc={imageSrc}
        className="h-24 w-24"
      />

      <div className="mt-2 flex  flex-col items-center justify-center">
        <h3 className="block text-base font-medium text-gray-700">{name}</h3>
        <h6 className="block text-sm font-medium text-gray-400">
          {secondaryField}
        </h6>
      </div>
      {button && (
        <Button className="px-3.5 py-0.5" onClick={onClickBtn}>
          {primaryCta}
        </Button>
      )}
    </div>
  );
};

export default AvatarCard;
