import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon, PhoneIcon, CogIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import { useAuthorList } from "../../lib/services/AuthorsListServices";
import Table from "../../components/Table";
import { ActionEditButton } from "../../table-components/Buttons";
import { ModalWrapper } from "../../utils/Modal";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { BASE_API, navigateSelf } from "../../utils/Helper";
import SearchBar from "../../components/SearchBar";
import Label from "../../components/Label";
import AvatarInfo from "../../components/AvatarInfo";
import AvatarCard from "../../components/AuthorCard";

const AuthorsList = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [AuthorId, setAuthorId] = useState(false);
  const [resetSearchTerm, setresetSearchTerm] = useState(false);
  const [currentstatus, setCurrentStatus] = useState(false);
  const [searchedAuthor, setSearchedAuthor] = useState(null);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const [modalTalentId, setModalTalentId] = useState("");

  //to manage toggle transition of headlessUI
  useEffect(() => {
    if (!modalTalentId) {
      setModalTalentId(AuthorId);
    } else {
      setTimeout(() => {
        setModalTalentId(AuthorId);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAuthorId, AuthorId]);

  const updateUserStatus = async () => {
    setModalPrimaryCtaLoading(true);
    if (AuthorId) {
      console.log("Author ID", AuthorId);
      //eslint-disable-next-line no-unused-vars
      const { data, error } = currentstatus
        ? await API.delete(`${BASE_API.TWITTER}/author/${AuthorId}`)
        : await API.post(`${BASE_API.TWITTER}/author`, {
            twitterId: _.find(authors, {
              id: AuthorId,
            })?.twitterId,
          });
      if (error) {
        toast.error(
          `Error ${error?.status ?? ""}: ${
            error?.message ?? "Error in updating user"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success(
          `Author has been ${
            currentstatus ? "disabled" : "enabled"
          } successfully`
        );
        setModalPrimaryCtaLoading(false);
        setAuthorId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const { authors, loading, error, mutate, isValidating, paginationInfo } =
    useAuthorList(pageNumberQuery, searchParamsQuery);
  console.log("Pagii", paginationInfo);
  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setAuthorId(data.id);
      setCurrentStatus(data?.status);
    }
  };

  const columns = [
    {
      field: "User ID",
      render: (rowData) => (
        <div className="flex  flex-row items-center px-4 text-left text-sm  capitalize text-gray-900">
          {rowData?.id}
        </div>
      ),
    },
    // null is rendering for name as we are not collecting the info
    {
      field: "name",
      render: (rowData) => (
        <div className="flex  flex-row items-center text-left text-sm font-medium capitalize  text-gray-900 ">
          {rowData?.name ?? null}
        </div>
      ),
    },
    {
      field: "Username",
      render: (rowData) => (
        <div className="flex  flex-row items-center text-left text-sm font-medium capitalize  text-gray-900 ">
          {rowData?.username ?? null}
        </div>
      ),
    },
    // {
    //   field: "Contact Info",
    //   render: (rowData) => (
    //     <div className="flex flex-col">
    //       {rowData?.email && (
    //         <div className=" flex flex-row items-center space-x-1 whitespace-nowrap text-left text-sm text-gray-500">
    //           <a href={`mailto:${rowData?.email}`}>
    //             {" "}
    //             <MailIcon className="h-4 w-4" />
    //           </a>
    //           <span>{rowData?.email ?? null}</span>
    //         </div>
    //       )}
    //       {rowData?.phone && (
    //         <div className="flex flex-row items-center space-x-1 whitespace-nowrap text-left text-sm text-gray-500">
    //           <a
    //             href={`tel:${
    //               rowData?.countryCode ? `+${rowData?.countryCode}-` : ""
    //             }${rowData?.phone}`}
    //           >
    //             <PhoneIcon className="h-4 w-4" />
    //           </a>
    //           <span>
    //             {rowData?.countryCode ? `+${rowData?.countryCode}-` : null}
    //             {rowData?.phone ?? "-NA-"}
    //           </span>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   field: "total jobs",
    //   render: (rowData) => (
    //     <div className=" flex flex-row items-center space-x-1 whitespace-nowrap text-left  text-sm uppercase text-gray-500 ">
    //       <CogIcon className="h-4 w-4" />:<p>{rowData?.jobsCount}</p>
    //     </div>
    //   ),
    // },

    {
      field: "status",
      render: (rowData) => (
        <div className="whitespace-nowrap text-left text-sm uppercase text-gray-500">
          {_.isNull(rowData?.enabled)
            ? "-NA-"
            : rowData?.enabled
            ? "enabled"
            : "disabled"}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" flex flex-row space-x-4 whitespace-nowrap text-left text-sm font-medium ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("delete", {
                id: rowData.id,
                status: rowData?.enabled,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(authors)) {
      return (
        <LoadContentHelper
          title="Author List"
          data={authors}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }
    return <Table column={columns} data={authors} />;
  };

  const onSearchAuthor = async (searchText) => {
    const { data, error, ...pr } = await API.get(
      `${BASE_API.TWITTER}/twitterHandle/${searchText}`
    );
    console.log("Er", pr);
    if (error) {
      console.log("In Error");
      toast.error(
        `Error ${error?.status ?? null}: ${
          error?.message ?? "This username not exists"
        }`
      );
      // setModalPrimaryCtaLoading(false);
    } else {
      toast.success("Found user ");
      setSearchedAuthor(data);
      // console.log();
      // setModalPrimaryCtaLoading(false);
      // setUserId(false);
    }
  };

  const onAddUser = async () => {
    const { data, error } = await API.post(`${BASE_API.TWITTER}/author`, {
      twitterId: searchedAuthor?.twitterId,
    });
    console.log("Error");
    if (error) {
      toast.error(
        `Error ${error?.status ?? ""}: ${
          error?.message ?? "Error in adding user"
        }`
      );
      // setModalPrimaryCtaLoading(false);
    } else {
      toast.success("User Added");
      // setModalPrimaryCtaLoading(false);

      setSearchedAuthor(null);
      setresetSearchTerm(true);

      // console.log();
      // setModalPrimaryCtaLoading(false);
      // setUserId(false);
    }
  };
  useEffect(() => {
    if (!toggle || !editData || !AuthorId || !searchedAuthor) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, AuthorId, searchedAuthor]);

  //dynamic page rendering based on URL
  useEffect(() => {
    console.log("Calling");
    // return;
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      console.log(typeof parsed, typeof searchParamsQuery, typeof pageNumberQuery, typeof total_pages, );
      return;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: updateUserStatus,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setAuthorId,
    },
    open: { value: AuthorId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };
  return (
    <Layout>
      <ModalWrapper
        fullTitle="Change Status"
        desc={`Are you sure you want to  ${
          _.find(authors, {
            id: modalTalentId,
          })?.enabled
            ? "disable"
            : "enable"
        }
      this user?`}
        config={modalConfig}
      />

      <div className="sticky top-0 z-50">
        <SectionHeading title="Authors" />
      </div>
      <div className="my-6 mx-6 flex flex-col border-b border-gray-200 bg-white">
        <h3 className="flex-[2] px-12 py-2 text-lg font-medium leading-6 text-black">
          Add Twitter Handle
        </h3>
        <div className="flex flex-row  gap-2">
          <div className=" flex flex-1 flex-col justify-center  ">
            <SearchBar
              showButton={true}
              primaryCta={"Find"}
              onSearch={onSearchAuthor}
              align={"center"}
              searchTerm={resetSearchTerm ? "" : null}
            />
          </div>

          <div className="flex-1 ">
            {searchedAuthor && searchedAuthor.name && (
              <AvatarCard
                className="flex-1"
                imageSrc={searchedAuthor?.profileImageUrl}
                name={searchedAuthor?.name}
                secondaryField={searchedAuthor?.username}
                onClickBtn={onAddUser}
              />
            )}
          </div>
        </div>
      </div>

      <main className="relative flex-1 overflow-y-auto focus:outline-none">
        <TableLayout
          title={`Authors List ${
            _.isNil(paginationInfo)
              ? ""
              : `(Total Users : ${paginationInfo?.totalRecords})`
          }`}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default AuthorsList;
