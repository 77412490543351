import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";

const Author = ({
  size,
  name,
  imageSrc,
  cta,
  onClick,
  darkmode,
  selected = false,
  type = "user",
  imageShape = "round-full",
  ...rest
}) => {
  const sizeStyle = () => {
    switch (size) {
      case "sm":
        return "h-8 w-8";
      case "md":
        return "h-10 w-10";
      case "lg":
        return "h-12 w-12";
      case "xl":
        return "h-16 w-16 ";
      default:
        return "";
    }
  };

  const nameStyle = () => {
    switch (size) {
      case "sm":
        return "text-xs";
      case "md":
        return "text-sm";
      case "lg":
        return "text-base";
      default:
        return "";
    }
  };

  const ctaStyle = () => {
    switch (size) {
      case "sm":
        return "text-xs font-normal";
      case "md":
        return "text-xs";
      case "lg":
        return "text-sm ";
      default:
        return "";
    }
  };
  const imageStyle = () => {
    switch (imageShape) {
      case "round-sm":
        return "rounded-sm";
      case "round-md":
        return "rounded-md";
      case "round-lg":
        return "rounded-lg";
      case "round-none":
        return "rounded-none";
      case "round-full":
        return "rounded-full";
      default:
        return "";
    }
  };

  return (
    <div
      onClick={onClick}
      className="flex-shrink-0 group block cursor-pointer"
      {...rest}
    >
      <div className="flex items-center">
        <div className=" flex-shrink-0 relative">
          <img
            className={classNames("inline-block", sizeStyle(), imageStyle())}
            src={
              imageSrc ||
              (type === "brand"
                ? "/assets/images/placeholder/genericPlaceholder.svg"
                : "/assets/images/placeholder/userPlaceholder.svg")
            }
            alt=""
          />
          {selected ? (
            <img
              className="absolute -top-1 -right-1"
              src="/assets/images/icon/Check circle.png"
              alt="profile"
            />
          ) : (
            ""
          )}
        </div>
        <div className="ml-3">
          <p
            className={classNames(
              nameStyle(),
              "font-medium",
              darkmode ? "text-gray-900" : "text-white"
            )}
          >
            {name}
          </p>
          <p
            className={classNames(
              ctaStyle(),
              darkmode
                ? "text-gray-500 group-hover:text-gray-700"
                : "text-gray-300 group-hover:text-gray-200"
            )}
          >
            {cta}
          </p>
        </div>
      </div>
    </div>
  );
};

Author.defaultProps = {
  size: "md",
  darkmode: true,
};

Author.propTypes = {
  /**
   * Name
   */
  name: PropTypes.string,

  /**
   *  Image Source
   */
  imageSrc: PropTypes.string,

  /**
   * CTA
   */
  cta: PropTypes.string,

  /**
   * On Click Action
   */
  onClick: PropTypes.func,

  /**
   * Size
   */
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),

  /**
   * Dark Mode
   */
  darkmode: PropTypes.bool,
};

export default Author;
