import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import Button from "./Button";

const EmptyState = ({
  imgSrc,
  title,
  message,
  cta,
  onClick,
  icon,
  ...rest
}) => {
  return (
    <div className="flex items-center justify-center w-full h-96 flex-col space-y-4  max-w-md m-auto text-center">
      <img src={imgSrc} width="200" className="filter grayscale" alt="" />
      <span className="font-bold">{title}</span>
      <span>{message}</span>
      {cta && (
        <Button onClick={onClick} iconPlacement="leading" icon={icon}>
          {cta}
        </Button>
      )}
    </div>
  );
};

EmptyState.defaultProps = {};

EmptyState.propTypes = {
  /**
   * Image Source
   */
  imgSrc: PropTypes.string,
  /**
   * Title
   */
  title: PropTypes.string,

  /**
   *  Message
   */
  message: PropTypes.string,

  /**
   * CTA
   */
  cta: PropTypes.string,

  /**
   * On Click Action
   */
  onClick: PropTypes.func,
};

export default EmptyState;
