import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SearchIcon } from "@heroicons/react/solid";
import Button from "../stories/Button";
import { toast } from "./Toast";

const SearchBar = ({
  size,
  align,
  primaryCta,
  onSearch,
  loading,
  searchTerm,
  showButton = true,
}) => {
  const widthStyle = () => {
    switch (size) {
      case "xs":
        return "py-1.5 text-xs w-1/4";
      case "sm":
        return "w-1/3 ";
      case "md":
        return "w-1/2";
      case "lg":
        return " w-3/4";
      case "xl":
        return " w-full";
      default:
        return "";
    }
  };

  const heightStyle = () => {
    switch (size) {
      case "xs":
        return "py-1.5 text-xs";
      case "sm":
        return "py-2 text-sm ";
      case "md":
        return "py-2 text-sm ";
      case "lg":
        return "py-2 text-base ";
      case "xl":
        return "py-3 text-base ";
      default:
        return "";
    }
  };

  const alignType =
    align === "center"
      ? "justify-center"
      : align === "left"
      ? "justify-start"
      : align === "right"
      ? "justify-end"
      : "";
  const searchInputRef = useRef(null);
  const buttonRef = useRef(null);

  //Setting Value of Search Input
  const [searchVal, setSearchVal] = useState("");

  //Change Handler
  const onChangeHandler = (e) => {
    setSearchVal(e.target.value);
    if (!e.target.value) {
      onSearch(e.target.value);
    }
  };

  //For initial url appending
  useEffect(() => {
    if (searchTerm) {
      setSearchVal(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-w-0 flex-1 md:px-6 lg:px-6 xl:col-span-6 ">
      <div
        className={classNames(
          "flex space-x-2 items-center px-6 py-1 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0",
          alignType
        )}
      >
        <div className={classNames("py-2", size ? widthStyle() : "w-full")}>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon
                className="h-5 w-5 text-gray-900"
                aria-hidden="true"
              />
            </div>
            <input
              ref={searchInputRef}
              id="search"
              name="search"
              className={classNames(
                "block w-full bg-white border border-gray-300 rounded-md  py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-800 focus:border-gray-800  ",
                size ? heightStyle() : ""
              )}
              placeholder="Search"
              type="search"
              onKeyUp={(e) => {
                if (e.key === "Enter" && e.target.value?.length > 1) {
                  onSearch(e.target.value);
                } else if (
                  e.key === "Enter" &&
                  e.target.value?.length < 2 &&
                  e.target.value?.length > 0
                ) {
                  if (showButton) buttonRef.current.shake();
                  toast.error("Search cannot be less than 2 characters");
                } else if (e.key === "Enter" && e.target.value?.length === 0) {
                  if (showButton) buttonRef.current.shake();
                  toast.error("Search field cannot be left blank");
                }
              }}
              value={searchVal}
              onChange={onChangeHandler}
            />
          </div>
        </div>
        {showButton ? (
          <Button
            className={classNames(
              !!loading && searchVal ? "px-3.5 py-0.5" : ""
            )}
            size={size}
            onClick={() => {
              if (searchInputRef?.current?.value?.length > 1) {
                onSearch(searchInputRef?.current?.value);
              } else if (
                searchInputRef?.current?.value?.length < 2 &&
                searchInputRef?.current?.value?.length > 0
              ) {
                buttonRef.current.shake();
                toast.error("Search cannot be less than 2 characters");
              } else if (searchInputRef?.current?.value?.length === 0) {
                buttonRef.current.shake();
                toast.error("Search field cannot be left blank");
              }
            }}
            loading={!!loading && !!searchVal}
            ref={buttonRef}
          >
            {primaryCta}
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  size: "xl",
  align: "right",
  primaryCta: "Search",
};

SearchBar.propTypes = {
  /**
   * Size
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

  /**
   * Button Style
   */
  align: PropTypes.oneOf(["left", "center", "right"]),
};

export default SearchBar;
