import React from "react";
import SectionHeading from "../stories/SectionHeading";
import Layout from "../components/sideBar/Layout";

const Home = () => {
  return (
    <Layout>
      <SectionHeading title={"Welcome to Milestand"} />
    </Layout>
  );
};

export default Home;
