import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon, PhoneIcon, CogIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import { useUserList } from "../../lib/services/UserService";
import Table from "../../components/Table";
import { ActionEditButton } from "../../table-components/Buttons";
import { ModalWrapper } from "../../utils/Modal";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { BASE_API, navigateSelf } from "../../utils/Helper";

const UserList = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [userId, setUserId] = useState(false);
  const [currentstatus, setCurrentStatus] = useState(false);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const [modalTalentId, setModalTalentId] = useState("");

  //to manage toggle transition of headlessUI
  useEffect(() => {
    if (!modalTalentId) {
      setModalTalentId(userId);
    } else {
      setTimeout(() => {
        setModalTalentId(userId);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserId, userId]);

  const updateUserStatus = async () => {
    setModalPrimaryCtaLoading(true);
    if (userId) {
      //eslint-disable-next-line no-unused-vars
      const { data, error } = await API.post(
        `${BASE_API.USER}/prod/updateUser?id=${userId}`,
        {
          enabled: currentstatus ? 0 : 1,
        }
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This user cannot be updated. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("User status updated Successfully");
        setModalPrimaryCtaLoading(false);
        setUserId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const { users, loading, error, mutate, isValidating, paginationInfo } =
    useUserList(pageNumberQuery, searchParamsQuery);

  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setUserId(data.id);
      setCurrentStatus(data?.status);
    }
  };

  const columns = [
    {
      field: "User ID",
      render: (rowData) => (
        <div className="flex  flex-row items-center px-4 text-left text-sm  capitalize text-gray-900">
          {rowData?.id}
        </div>
      ),
    },
    // null is rendering for name as we are not collecting the info
    // {
    //   field: "name",
    //   render: (rowData) => (
    //     <div className="flex  flex-row items-center text-left text-sm font-medium capitalize  text-gray-900 ">
    //       {rowData?.name ?? null}
    //     </div>
    //   ),
    // },

    {
      field: "Contact Info",
      render: (rowData) => (
        <div className="flex flex-col">
          {rowData?.email && (
            <div className=" flex flex-row items-center space-x-1 whitespace-nowrap text-left text-sm text-gray-500">
              <a href={`mailto:${rowData?.email}`}>
                {" "}
                <MailIcon className="h-4 w-4" />
              </a>
              <span>{rowData?.email ?? null}</span>
            </div>
          )}
          {rowData?.phone && (
            <div className="flex flex-row items-center space-x-1 whitespace-nowrap text-left text-sm text-gray-500">
              <a
                href={`tel:${
                  rowData?.countryCode ? `+${rowData?.countryCode}-` : ""
                }${rowData?.phone}`}
              >
                <PhoneIcon className="h-4 w-4" />
              </a>
              <span>
                {rowData?.countryCode ? `+${rowData?.countryCode}-` : null}
                {rowData?.phone ?? "-NA-"}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "total jobs",
      render: (rowData) => (
        <div className=" flex flex-row items-center space-x-1 whitespace-nowrap text-left  text-sm uppercase text-gray-500 ">
          <CogIcon className="h-4 w-4" />:<p>{rowData?.jobsCount}</p>
        </div>
      ),
    },

    {
      field: "status",
      render: (rowData) => (
        <div className="whitespace-nowrap text-left text-sm uppercase text-gray-500">
          {_.isNull(rowData?.enabled)
            ? "-NA-"
            : rowData?.enabled
            ? "enabled"
            : "disabled"}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" flex flex-row space-x-4 whitespace-nowrap text-left text-sm font-medium ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("delete", {
                id: rowData.id,
                status: rowData?.enabled,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(users)) {
      return (
        <LoadContentHelper
          title="User List"
          data={users}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }
    return <Table column={columns} data={users} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !userId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, userId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;

      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: updateUserStatus,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setUserId,
    },
    open: { value: userId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };
  return (
    <Layout>
      <ModalWrapper
        fullTitle="Change Status"
        desc={`Are you sure you want to  ${
          _.find(users, {
            id: modalTalentId,
          })?.enabled
            ? "disable"
            : "enable"
        }
      this user?`}
        config={modalConfig}
      />

      <div className="sticky top-0 z-50">
        <SectionHeading title="Users" />
      </div>

      <main className="relative flex-1 overflow-y-auto focus:outline-none">
        <TableLayout
          title={`User List ${
            _.isNil(paginationInfo)
              ? ""
              : `(Total Users : ${paginationInfo?.totalRecords})`
          }`}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default UserList;
