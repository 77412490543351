import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import TextInput from "../../components/TextInput";
import Button from "../../stories/Button";
import Label from "../../components/Label";
import _ from "lodash";

import { passwordResetValidationSchema } from "../../forms/validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { BASE_API } from "../../utils/Helper";

export default function ForgotPassword() {
  const initialValues = { email: "" };
  const buttonRef = useRef(null);
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async (values) => {
    const apiEndpoint = `${BASE_API.USER}/prod/forgotPassword`;
    const { error } = await API.post(apiEndpoint, {
      email: values?.email,
    });

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );

      buttonRef.current.shake();
    } else {
      setEmailSent(true);
      toast.success("Email sent");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordResetValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="flex min-h-screen flex-col bg-gray-100 py-12 sm:px-6 lg:px-8 ">
          <div className="mt-24 px-16 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mx-auto mt-6 w-auto text-center text-3xl font-extrabold uppercase text-gray-900">
              urdu reader
            </h2>
            {!emailSent && (
              <>
                <div className="mx-3">
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Trouble Logging In
                  </h2>
                  <h5 className="mt-2.5 text-center text-sm leading-5 text-gray-500">
                    Enter your email and we’ll send you a link to reset your
                    password
                  </h5>
                </div>
              </>
            )}
          </div>
          {emailSent && (
            <div className="mx-auto mt-16 max-w-xl">
              <h2 className="mt-6 text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                We have sent you a mail on your Email address with the Reset
                Password Link.
              </h2>
            </div>
          )}

          {!emailSent && (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <Form className="space-y-7">
                  <div>
                    <Label labelFor="email">Email Address</Label>
                    <TextInput
                      name="email"
                      id="email"
                      type="email"
                      placeholder="joe@milestand.com"
                    />
                  </div>

                  <Button
                    ref={buttonRef}
                    type="submit"
                    className="w-full"
                    loading={isSubmitting}
                  >
                    Send Reset Password Link
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}
