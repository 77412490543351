/* eslint-disable no-unused-vars */

import { toast } from "../../../components/Toast";
import { API } from "../API";
import { ADMIN_NAVIGATON } from "../../../utils/SidebarNav";

const getCurrentUserDetails = () => {
  const curUserDetail = localStorage.getItem("current_user_details");
  if (curUserDetail) return JSON.parse(curUserDetail);

  return null;
};

export const CurrentUser = {
  setToken: (token) => localStorage.setItem("user_auth_token", token),
  getToken: () => localStorage.getItem("user_auth_token"),
  logout: async () => {
    localStorage.removeItem("user_auth_token");
    localStorage.removeItem("current_user_details");
    toast.success("Logging out...");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 500);
  },
  isLoggedIn: () => !!localStorage.getItem("user_auth_token"),
  getDetails: () => getCurrentUserDetails(),

  getProfile: () => {
    return {
      firstName: CurrentUser.getDetails()?.user?.firstName,
      lastName: CurrentUser.getDetails()?.user?.lastName,
      email: CurrentUser.getDetails()?.user?.email,
      image: CurrentUser.getDetails()?.user?.image,
    };
  },

  getType: () => {
    return CurrentUser.getDetails()?.claims?.entity?.type;
  },

  setDetails: (data) => {
    localStorage.setItem("current_user_details", data);
  },

  sideBarNavigation: () => {
    return ADMIN_NAVIGATON;
  },
};

export const logout = async () => {
  // Logout from server
  const { data, error } = await API.delete("/auth/logout");
  localStorage.removeItem("current_user_details");
  localStorage.removeItem("user_auth_token");
  toast.success("Logout successfully");
  setTimeout(() => {
    window.location.href = "/login";
  }, 1500);
};
