import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import Loader from "./Loader";
import { useImperativeHandle, useState } from "react";
import React from "react";

export const Button = React.forwardRef(
  (
    {
      size,
      buttonStyle,
      type,
      iconAlso,
      iconPlacement,
      circular,
      children,
      className,
      loading,
      icon,
      ...rest
    },
    buttonRef
  ) => {
    const [shakeClass, setShakeClass] = useState(false);
    const style = () => {
      switch (buttonStyle) {
        case "secondary":
          return "bg-gray-200 text-gray-700 hover:bg-gray-300";
        case "white":
          return "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300";
        case "link":
          return "bg-white text-gray-700  border border-gray-300";
        case "danger":
          return "bg-red-600 hover:bg-red-700 text-white border border-red-300";
        case "success":
          return "bg-green-600 hover:bg-green-700 text-white border border-green-300";
        case "disabled":
          return "bg-gray-500 text-gray-200  cursor-not-allowed hover:bg-gray-500 opacity-50";
        default:
          return "bg-gray-800 text-white hover:bg-gray-900";
      }
    };

    const sizeStyle = () => {
      switch (size) {
        case "xs":
          return "px-2.5 py-1.5 text-xs";
        case "sm":
          return "px-3 py-2 text-sm ";
        case "md":
          return "px-4 py-2 text-sm";
        case "lg":
          return "px-6 py-2 text-base";
        case "xl":
          return "px-6 py-3 text-base";
        default:
          return "";
      }
    };

    const circularStyle = size === "xs" ? "rounded" : "rounded-md";

    const iconPlacementStyle =
      iconPlacement === "leading" ? "flex-row" : "flex-row-reverse";

    const iconHeight = size === "xs" || size === "sm" ? "h-4 w-4" : "h-5 w-5";

    const onlyIconStyle = () => {
      switch (size) {
        case "xs":
          return "h-5 w-5 m-1";
        case "sm":
          return "h-5 w-5 m-1.5";
        case "md":
          return "h-5 w-5 m-2";
        case "lg":
          return "h-6 w-6 m-2";
        case "xl":
          return "h-6 w-6 m-3";
        default:
          return "";
      }
    };

    useImperativeHandle(buttonRef, () => ({
      shake() {
        if (shakeClass) return;
        setShakeClass(true);
        setTimeout(() => {
          setShakeClass(false);
        }, 1000);
      },
    }));

    return (
      <button
        type={type}
        ref={buttonRef}
        className={classNames(
          "flex flex-row items-center border border-transparent font-medium  shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  justify-center",
          style(),
          circular ? "rounded-full" : circularStyle,
          className,
          loading && "pointer-events-none opacity-60",
          shakeClass && "animate-shake",
          buttonStyle === "danger"
            ? "focus:ring-red-500"
            : "focus:ring-gray-500"
        )}
        {...rest}
      >
        {loading ? (
          <Loader
            className={classNames(
              buttonStyle === "white" || buttonStyle === "secondary"
                ? "text-gray-900"
                : "text-white",
              sizeStyle()
            )}
          />
        ) : (
          <>
            <div
              className={classNames(
                "items-center text-center flex flex-row",
                iconPlacementStyle,
                children && sizeStyle()
              )}
            >
              {icon?.type && (
                <icon.type
                  className={classNames(
                    children
                      ? iconPlacement === "leading"
                        ? "-ml-0.5 mr-2 "
                        : "ml-2 -mr-0.5"
                      : onlyIconStyle(),
                    iconHeight
                  )}
                  aria-hidden="true"
                />
              )}
              {children}
            </div>
          </>
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  size: "md",
  buttonStyle: "primary",
  circular: false,
  type: "button",
  loading: false,
};

Button.propTypes = {
  /**
   * Size
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

  /**
   * Button Style
   */
  buttonStyle: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
    "danger",
    "disabled",
    "success",
  ]),

  /**
   * Icon with Text
   */
  iconAlso: PropTypes.bool,

  /**
   * Icon Placement
   */
  iconPlacement: PropTypes.oneOf(["leading", "trailing"]),

  /**
   * Button Circular
   */
  circular: PropTypes.bool,

  /**
   * loading animation
   */
  loading: PropTypes.bool,

  /**
   * loading animation
   */
  icon: PropTypes.node,
};

export default Button;
