import React from "react";

const Table = ({ column, data }) => {
  return (
    <div className="max-w-full mx-auto bg-gray-100">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {column?.map((col, idx) => {
                      return (
                        <th
                          key={idx}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {col?.field}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((row, index) => {
                    return (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        {column?.map((col, idx) => {
                          return (
                            <td key={idx} className="px-6 py-4">
                              {col.render(row)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
