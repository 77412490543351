import { useAPI } from "../network/SWR";
import { BASE_API, queryStringParseFuntion } from "../../utils/Helper";
export const useAuthorList = (pageParam = "1", searchTerm) => {
  console.log("page", pageParam);
  const queryObject = { pageNo: pageParam, q: searchTerm, pageSize: 10 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `${BASE_API.TWITTER}/author${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  console.log("Data", data);
  return {
    authors: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo:
      {
        page: data?.pageNo,
        totalRecords: data?.totalRecords,
        perPage: data?.pageSize,
        totalPages: data?.totalPages,
      } || null,
    //

    // "pagination": {
    //   "page": 1,
    //   "totalRecords": 6401,
    //   "perPage": 10,
    //   "totalPages": 641
    // }
  };
};

//view single job
export const useAuthor = (userId) => {
  const queryObject = { id: userId };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `${BASE_API.JOB}/author${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    jobData: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
